import React from "react";
import PropTypes from "prop-types";
import { kebabCase } from "lodash";
import Helmet from "react-helmet";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import { Section, Container } from "rbx";

export const BlogPostTemplate = ({
  content,
  contentComponent,
  description,
  termin,
  tags,
  title,
  helmet
}) => {
  const PostContent = contentComponent || Content;
  return (
    <Section>
      {helmet || ""}
      <Container>
        <Section>
          <h1 className="title is-size-3 is-size-5-touch has-text-weight-bold is-bold-light">
            {title}
          </h1>
          {termin && (
            <p>
              <h3
                className="subtitle is-size-5 has-text-weight-bold is-bold-light"
                style={{ marginBottom: "1em" }}
              >
                {termin}
              </h3>
            </p>
          )}
          <p>{description}</p>
          <PostContent content={content} />
        </Section>
      </Container>
    </Section>
  );
};

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object
};

const BlogPost = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <BlogPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        termin={post.frontmatter.termin}
        helmet={
          <Helmet titleTemplate="%s | TV Grossweikersdorf">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
      />
    </Layout>
  );
};

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object
  })
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        termin
        description
        tags
      }
    }
  }
`;
